import { UrlService } from './url.service/url.service';
import { JwtService } from '@services/jwt.service/jwt.service';
import { ZipService } from '@services/zip.service/zip.service';
import { MfaService } from '@services/mfa.service/mfa.service';
import { NpiService } from '@services/npi.service/npi.service';
import { FileService } from '@services/file.service/file.service';
import { RequestService } from './request.service/request.service';
import { StorageService } from './storage.service/storage.service';
import { CacheService } from '@services/cash.service/cash.service';
import { HcpcsService } from '@services/hcpcs.service/hcpcs.service';
import { OrdersService } from '@services/orders.service/orders.service';
import { UserDataService } from './user-data.service/user-data.service';
import { ScreenService } from '@services/screen.service/screen.service';
import { ProductsService } from '@services/products.service/products.service';
import { LocationsService } from '@services/locations.service/locations.service';
import { InsuranceService } from '@services/insurance.service/insurance.service';
import { DiagnosisService } from '@services/diagnosis.service/diagnosis.service';
import { ConstantsService } from '@services/constants.service/constants.service';
import { EnvConfigService } from '@services/env-config.service/env-config.service';
import { IpAddressService } from '@services/ip-address.service/ip-address.service';
import { AgreementsService } from '@services/agreements.service/agreements.service';
import { PhysiciansService } from '@services/physicians.service/physicians.service';
import { OrderDraftService } from '@services/order-draft.service/order-draft.service';
import { ErrorHandlingService } from './error-handling.service/error-handling.service';
import { ConfirmModalService } from '@services/confirm-modal.service/confirm-modal.service';
import { ConfigurationService } from '@services/configuration.service/configuration.service';
import { PayerRuleSetService } from '@services/payer-rule-set.service/payer-rule-set.service';
import { UserSignatureService } from '@services/user-signature.service/user-signature.service';
import { NpiFacilitiesService } from '@services/npi-facilities.service/npi-facilities.service';
import { UserManagementService } from '@services/user-management.service/user-management.service';
import { ApplicationInsightsService } from '@services/application-insights.service/application-insights.service';

export const SERVICES = [
  UrlService,
  MfaService,
  ZipService,
  JwtService,
  NpiService,
  FileService,
  CacheService,
  HcpcsService,
  ScreenService,
  OrdersService,
  StorageService,
  RequestService,
  UserDataService,
  ProductsService,
  ConstantsService,
  EnvConfigService,
  DiagnosisService,
  IpAddressService,
  InsuranceService,
  LocationsService,
  AgreementsService,
  PhysiciansService,
  OrderDraftService,
  ConfirmModalService,
  NpiFacilitiesService,
  PayerRuleSetService,
  ConfigurationService,
  UserSignatureService,
  ErrorHandlingService,
  UserManagementService,
  ApplicationInsightsService,
];
