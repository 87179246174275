import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';

import { URLS } from '@const/urls';
import { STORAGE } from '@const/storage';
import { EnvConfig } from '@models/env-config';
import { SVG_CONFIG } from '@const/svg-config';
import { BaseState } from '@reducers/base.reducer';
import { MatDialog } from '@angular/material/dialog';
import { AGREEMENTS_MODAL } from '@const/modals-configs';
import { getUrlWithoutParams } from '@helpers/url.helper';
import { unsubscribeRequests } from '@actions/base.actions';
import { loadGenesysChat } from '@helpers/genesys-chat.helper';
import { ScreenService } from '@services/screen.service/screen.service';
import { FORBIDDEN_REDIRECT_URLS } from '@const/forbidden-redirect-urls';
import { StorageService } from '@services/storage.service/storage.service';
import { UserDataService } from '@services/user-data.service/user-data.service';
import { ConstantsService } from '@services/constants.service/constants.service';
import { EnvConfigService } from '@services/env-config.service/env-config.service';
import { AgreementsService } from '@services/agreements.service/agreements.service';
import { ApplicationInsightsService } from '@services/application-insights.service/application-insights.service';
import { AgreementsModalComponent } from '@components/agreements-modal/components/agreements-modal/agreements-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  public pageClass: string = '';

  private readonly ENV_CONFIG: EnvConfig = this.envConfigService.getCurrentEnvConfig();

  constructor(
    public readonly uds: UserDataService,
    private readonly router: Router,
    private readonly matDialog: MatDialog,
    private readonly domSanitizer: DomSanitizer,
    private readonly baseStore: Store<BaseState>,
    private readonly screenService: ScreenService,
    private readonly storageService: StorageService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly constantsService: ConstantsService,
    private readonly envConfigService: EnvConfigService,
    private readonly agreementsService: AgreementsService,
    private readonly applicationInsightsService: ApplicationInsightsService,
  ) {
    this.setStorageBodyClasses();
  }

  public ngOnInit(): void {
    this.navigationStart();
    this.navigationEnd();
    this.ENV_CONFIG.isLoadGenesysChat && loadGenesysChat();
    SVG_CONFIG.map(o => this.generateIcon(o.svgName, o.path));
    this.applicationInsightsService.loadAppInsights();
    this.constantsService.getProductConstants();
    this.constantsService.getOrderStateConstants();
    this.constantsService.getEmergencyRelationshipConstants();
  }

  public updateWindowWidth(): void {
    this.screenService.setMobileSettings();
  }

  private generateIcon(name: string, path: string): void {
    this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
  }

  private navigationStart(): void {
    this.router.events
      .pipe(filter(routerEvent => routerEvent instanceof NavigationStart))
      .subscribe(() => this.baseStore.dispatch(unsubscribeRequests()));
  }

  private navigationEnd(): void {
    this.router.events
      .pipe(filter((routerEvent: any) => routerEvent instanceof NavigationEnd))
      .subscribe((data: NavigationEnd) => {
        this.setPageClass(data);
        !this.isAuthPages(data) && this.setRedirectUrl(data.url);
        !this.isAuthPages(data) && this.checkAgreements();
      });
  }

  private setRedirectUrl(url: string): void {
    !FORBIDDEN_REDIRECT_URLS.some(it => url.indexOf(it) !== -1)
      ? this.storageService.set(STORAGE.redirectUrl, getUrlWithoutParams(url))
      : this.storageService.remove(STORAGE.redirectUrl);
  }

  private setPageClass(data: NavigationEnd): void {
    this.pageClass && document.body.classList.remove(this.pageClass);
    this.pageClass = `_p-${data.urlAfterRedirects.split('/')[1] + (data.urlAfterRedirects.split('/')[2] ? '-' + data.urlAfterRedirects.split('/')[2].split('?')[0] : '')}`;
    document.body.classList.add(this.pageClass);
  }

  private checkAgreements(): void {
    this.agreementsService.getAnyUnsignedDocumentAgreements$().subscribe(isAnyDocuments => isAnyDocuments && this.openAgreementsModal());
  }

  private isAuthPages(data: NavigationEnd): boolean {
    return !(data.url.indexOf(URLS.authorization) === -1) || !(data.url.indexOf(URLS.prescriber) === -1);
  }

  private openAgreementsModal(): void {
    this.matDialog
      .open(AgreementsModalComponent, { ...AGREEMENTS_MODAL })
      .afterClosed()
      .subscribe();
  }

  private setStorageBodyClasses(): void {
    const classes: string[] = this.storageService.get(STORAGE.bodyClasses);
    classes?.length && document.body.classList.add(...classes);
  }
}
